.search_container {
  background-color: var(--bg-primary);
  width: 100%;
  height: max-content;
}
.landtiles {
  display: flex;
  height: max-content;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
@media (max-width: 570px) {
  .landtiles {
    justify-content: center;
  }
}
