.form_card {
  display: flex;
  background: white;
  margin: auto;
  width: 90vw;
  max-width: 1000px;
  padding: 5rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  flex-direction: column;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  align-items: center;
  overflow-x: hidden;
}
.form_status {
  /* padding-bottom: 2rem; */
  /* margin-bottom: 10px; */
  max-width: 500px;
}
.form_container {
  display: flex;
  width: 60vw;
  min-width: 300px;
  max-width: 900px;
  flex-direction: column;
  padding: 1rem;
}
.form_button {
  width: 60vw;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
}
.form_field {
  width: 100%;
  display: flex;
}

.form_label {
  font-size: large;
  margin-top: 10px;
  width: max-content;
  display: block;
}

.form_valid_error {
  color: red;
}

/* Small screens */
@media only screen and (max-width: 600px) {
  .form_card {
    width: 100%;
    margin: auto;
    padding: 0rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .form_status {
    margin-left: 50vw;
    margin-right: 10vw;
  }
}
