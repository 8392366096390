.dash_card {
  display: flex;
  background: white;
  margin: auto;
  width: 20vw;
  min-width: 200px;
  padding: 5% 15%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 2em;
  font-weight: 500;
  flex-direction: column;

  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.dash_card:hover {
  background-color: rgb(226, 226, 226);
}
.dashboard {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.dash_card > h2 {
  text-align: center;
  width: max-content;
}
.form_container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.form_field {
}

.form_label {
  font-size: large;
}

.form_valid_error {
  color: red;
}

/* Small screens */
@media only screen and (max-width: 600px) {
  .dash_card {
    width: 70vw;
    font-size: 1.2em;
  }
}
