.Card {
  border-radius: 20px;
  width: 250px;
  height: max-content;
  overflow: hidden;
  margin: 40px 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}
.imageland {
  width: 100%;
  height: 200px;
}
.Location {
  color: white;
  padding: 5px 0;
  background-color: rgb(131, 131, 133);
  width: 120%;
}
.details {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 10px 20px 0 20px;
}

.cost {
  font-size: 1.3em;
  font-weight: 600;
}
.total_cost {
  font-size: 1em;
  font-weight: 500;
  color: gray;
  margin-bottom: 2px;
}
.btn {
  background-color: rgb(41, 63, 136);

  width: 100%;

  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  padding: 10px 0;
  font-size: 1.2em;
  font-weight: 500;
  color: white;
}
.btn:hover {
  background-color: rgb(21, 39, 97);
}

.land_type {
  display: flex;
  width: 100%;
  margin-top: 10px;
  font-weight: 600;
  font-size: 1em;
}

.listingType {
  margin-left: auto;
  width: min-content;
  font-size: 1em;
  font-weight: 600;
}
@media (max-width: 570px) {
  .Card {
    width: 80vw;
    height: max-content;
  }
}
