.filter_container {
  background-color: white;
}
.filterContainer {
  min-width: 300px;
  margin: 20px;
}

.display {
  display: block !important;
}
.filter_button {
  background-color: rgb(32, 32, 117);
  width: 100vw;
  padding: 10px;
  font-size: 1.2em;
  color: white;
  z-index: 98;
  position: fixed;
  top: 95%;
  display: none;
}
.filter_button:active {
  background-color: rgb(13, 13, 51);
}

@media (max-width: 570px) {
  .filterContainer {
    position: fixed;
    z-index: 99;
    height: 100vh;
    background-color: white;
    width: 100vw;
    margin: 0;
    padding: 10px;
    display: none;
  }
  .filter_button {
    display: block;
  }
}
