:root {
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  --text-primary: #000000;
  --text-secondary: #585252;
  --bg-primary: #ececec;
  --bg-secondary: #b6b6b6;
  --transition-speed: 600ms;
}

body {
  margin: 0;
  padding: 0;
  color: var(--text-primary);
  min-height: 100vh !important;
  overflow-x: hidden !important;
}

/* Small screens */
@media only screen and (max-width: 600px) {
  :root {
    font-size: 14px;
  }
}

/* Large screens */
@media only screen and (min-width: 600px) {
}

/* Ant Design*/
.ant-card-body {
  padding: 0px
}

/* Pepper content*/

.tw-h2 {
  font-size: 44px;
  line-height: 60px;
}
.tw-h4,
.tw-h5 {
  line-height: 24px;
}

.tw-h5 {
  font-size: 18px;
}
.paragraph-lg {
  font-size: 18px;
  line-height: 32px;
}

.tw-h1 {
  font-size: 48px;
  line-height: 56px;
}

.tw-font-semibold {
  font-weight: 600;
}

.Button-module--default--2nGLz {
  height: 48px;
  padding-left: 32px;
  padding-right: 32px;
}

.Button-module--button--3-a61 {
  transition: color 0.3s ease-in, background-color 0.3s ease-in;
}

.Button-module--primary--Zwv2c {
  background: #1700e6;
  color: #fff;
} 

.Button-module--primary--Zwv2c:hover {
  background: #1200b3;
}

.tw-h4 {
  font-size: 20px;
}

.button-flex {
  display: inline-flex !important;
  margin-right: 20px !important;
}
.Button-module--large--2Qg2d {
  height: 56px;
  padding-left: 32px;
  padding-right: 32px;
}
.Button-module--rounded--3dVkS {
  border-radius: 9999px;
}
._-module--container--3q17W {
  margin-left: 30px;
}
@media (min-width: 1024px) {
  ._-module--container--3q17W > :first-child {
    -webkit-animation: _-module--translateLeft--2xfGM 1s
      cubic-bezier(0.165, 0.84, 0.44, 1) both;
    animation: _-module--translateLeft--2xfGM 1s
      cubic-bezier(0.165, 0.84, 0.44, 1) both;
  }

  ._-module--container--3q17W > :nth-child(2) {
    -webkit-animation: _-module--translateRight--HFHHS 1s
      cubic-bezier(0.165, 0.84, 0.44, 1) both;
    animation: _-module--translateRight--HFHHS 1s
      cubic-bezier(0.165, 0.84, 0.44, 1) both;
  }
}

@media (min-width: 640px) {
  .tw-h1 {
    font-size: 64px;
    line-height: 72px;
  }
}


@media (min-width: 768px) {
  ._-module--heroImg--1F3_1 {
    max-width: 520px;
    width: auto;
  }
}