.form_card {
  display: flex;
  background: white;
  width: max-content;
  height: 70vh;
  min-height: 600px;
  justify-content: center;
  padding: 5rem 5rem;
  font-size: 1.8em;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  margin: 10vh auto 10vh auto;
}
.btn {
  margin-top: 20px;
}
.heading {
  text-align: left;
  margin-right: auto;
  margin-top: 30px;
}
.form_container {
  display: flex;
  flex-direction: column;
}

.form_label {
  font-size: large;
  text-align: left;
  font-size: 0.7em;
}

.form_valid_error {
  color: red;
  font-size: 0.6em;
}

.link {
  margin-left: auto;
  margin-right: auto;
  font-size: 0.6em;
  margin-top: 20px;
  text-decoration: underline;
}

/* Small screens */
@media only screen and (max-width: 600px) {
  .form_card {
    width: 100%;
    height: 100vh;
    margin: auto;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
