.form_card {
  display: flex;
  background: white;
  width: 90vw;
  max-width: 500px;
  height: min-content;
  justify-content: center;
  padding: 20px 5rem;
  font-size: 1.8em;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.btn {
  margin-top: 20px;
  /* padding: 20px 20px; */
}
.bg_grey {
  background-color: #b8c6db !important;
  background-image: linear-gradient(315deg, #b8c6db 0%, #f5f7fa 74%) !important;
  height: 100vh;
  min-height: 600px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.heading {
  text-align: left;
  margin-right: auto;
  margin: 10px 0;
  /* font-size: 1.5em; */
}
.form_container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: max-content;
  width: 100%;
}

.form_valid_error {
  color: red;
  font-size: 0.6em;
}
.Input {
  /* padding: 20px 20px; */
  text-align: left;
  margin-top: 10px;
  margin-bottom: 5px;
}
.link {
  margin-left: auto;
  margin-right: auto;
  font-size: 0.6em;
  margin-top: 20px;
  text-decoration: underline;
}

@media only screen and (max-width: 600px) {
  .form_card {
    width: 100%;
    height: 100vh;

    margin: auto;
  }
  .Input {
    margin-top: 10px;
    padding: 10px 10px;
    margin-bottom: 2px;
  }
  .btn {
    margin-top: 20px;
    padding: 10px 10px;
  }
  .heading {
    text-align: left;
    margin-right: auto;
    margin: 30px 0;
    /* font-size: 1.5em; */
  }
}
