.form_card {
  display: flex;
  background: white;
  margin: auto;
  width: fit-content;
  padding: 5rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  flex-direction: column;
  align-items: center;
}

.form_container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.form_label {
  font-size: large;
}

.form_valid_error {
  color: red;
}

/* Small screens */

.PropertyDetails {
  display: flex;
  width: 100%;
  height: max-content;
  margin: 0px auto 30px 10px;
  flex-wrap: wrap;
  padding: 40px 30px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 20px;
  justify-content: space-between;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}
.AdditionalDetails {
  justify-content: flex-start !important;
}
.Property_heading {
  width: 100%;
  font-size: 1.6em;
  font-weight: 600;
  color: rgb(15, 15, 116);
}
.details_box {
  display: flex;
  flex-direction: column;
  max-width: 280px;
  margin: 20px 20px 20px 0;
}
.details_box > h2 {
  font-size: 1.1em;
  color: gray;
}
.details_box > h3 {
  margin-top: 5px;
  font-size: 1.2em;
  font-weight: 600;
}
.top_container {
  width: 100%;
  display: flex;
  padding: 20px 50px;
  align-items: center;
  justify-content: space-between;
  height: 50vh;
}
.img_container {
  width: 60vw;
  overflow-x: hidden;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}
.image1 {
  max-height: 100%;
}
.SideCard {
  min-width: 250px;
  width: 25vw;
  position: fixed;
  background-color: white;
  right: 100%;
  left: 60%;
  margin-left: 100px;
  top: 80px;
  border-radius: 20px;
  padding: 30px 25px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.SideCard > h1 {
  font-weight: 600;
  font-size: 1.5em;
  margin-bottom: 20px;
  color: rgb(15, 15, 116);
}
.SideCard > h2 {
  font-weight: 600;
  font-size: 1.2em;
  margin-top: 20px;
  color: rgb(15, 15, 116);
}
.SideCard > ul {
  list-style: disc;
}
.SideCard > ul > li {
  font-size: 1.2em;
  font-weight: 600;
  margin-left: 25px;
}

.SideCard > Form > input {
  width: 50%;
  margin-bottom: 10px;
}
.mid_container {
  width: 60%;
  display: flex;
  flex-direction: row;
  padding: 20px 40px;
  margin-top: 20px;
}
.details_container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.location {
  width: 100%;
  min-height: 400px;
  margin: 0px auto 30px 10px;
  padding: 40px 30px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  align-items: center;
  justify-content: center;
}
.propety_video {
  margin: 0px auto 30px 20px;
  padding: 40px 30px;
  width: 60%;
}

@media (max-width: 840px) {
  .top_container {
    flex-direction: column;
    width: 95vw;
    margin: 10px auto;
    height: max-content;
    padding: 0;
  }
  .img_container {
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    margin: 0;
  }
  .SideCard {
    position: initial;
    margin-left: 0;
    width: 100%;
  }
  .mid_container {
    width: 100%;
    padding: 0;
  }
  .location {
    margin: 10px auto;
    width: 95%;
  }
  .PropertyDetails {
    width: 95%;
    max-height: 100%;
    margin: 20px auto;
  }

  .propety_video {
    margin: 20px auto;
    padding: 10px 20px;
    width: 100%;
  }
}
@media (max-width: 600px) {
  .form_card {
    width: 100%;
    margin: auto;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
