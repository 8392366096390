.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #000;
  height: 3rem;
}

.nav_head, .nav_head:hover, .nav_head:focus, .nav_head:active {
    color: #fff;
    font-size: 1.5rem;
    margin: 0 0.5rem;
    text-decoration: none;
}

.nav_link, .nav_link:hover, .nav_link:focus, .nav_link:active {
  color: #fff;
  font-size: 1rem;
  margin: 0 0.5rem;
  text-decoration: none;
}

.nav_body {
  display: flex;
  margin: 0 0.5rem;
}
