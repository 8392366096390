@tailwind base;
@tailwind components;
@tailwind utilities;
.Property-Tiles-Container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: max-content;
  flex-wrap: wrap;
}

.Property-Tile {
  width: 300px;
  border-radius: 10px;
  height: max-content;
  min-height: 300px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 50px;
  overflow: hidden;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.Property-Tile > img {
  max-height: 200px;
  width: 100%;
  overflow: hidden;
}
.property-tile-content {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 80px;
  width: 100%;
}
.property-tile-content > h1 {
  font-weight: 600;
  text-align: center;
  font-size: 1.3em;
  margin-top: 20px;
}

.fontsize {
  font-size: 1em;
}
.property-button {
  background-color: blue;
  color: white;
  width: 300px;
  padding: 10px 15px;
  font-size: 0.9em;
  margin-top: 20px;
}
.property-button:hover {
  background-color: rgb(16, 16, 102);
}
.Service-Card-holder {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: max-content;
  padding: 0 0 20px 0;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  align-items: center;
  margin: 0 auto 50px auto;
}
.review-img {
  width: 100%;
}
.ServiceCard {
  display: flex;
  justify-content: center;
  width: min-content;
  flex-wrap: wrap;
  min-width: 400px;
  min-height: 400px;
  margin: 0 20px;
  margin-top: 40px;
  height: min-content;
  border-radius: 10px;
  flex-direction: column;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
  padding: 0 0 1% 1.2%;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  transition: all 300ms;
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  -ms-transition: all 300ms;
  -o-transition: all 300ms;
  background-color: lightskyblue;
  box-sizing: border-box;
  /* color:white; */
}

.ServiceCard:hover {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
}
/* .ServiceCard:hover {
  background-color: rgb(10, 0, 0);
  color: white;
} */

.Property-Container {
  background-color: rgba(214, 214, 214, 0.11);
  width: 100%;
  padding: 0 0 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Service-heading {
  text-align: center;
  font-size: 2.2em;
  margin: 40px 0 0 0;
  font-weight: 600;
  padding: 0 5px;
}
.Service-Card-title {
  font-weight: 700;
  font-size: 2em;
  margin: 30px auto 10px 0;
  text-align: left;
}
.point-desc {
  font-size: 1em;
  width: 95%;
}
ul > li {
  font-size: 1.3em;
  font-weight: 600;
  margin-top: 10px;

  /* list-style-type: disc; */
}
.Service-desc {
  margin-bottom: 10px;
}

.fbox {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 50px;
  margin: 0 10px;
}

.stats {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 0 20px;
  max-width: 800px;
  margin: 50px auto;
  margin-bottom: 100px;
}
.f-digit {
  font-size: 3em;
  color: blue;
  font-weight: 600;
}
.f-text {
  font-size: 1.2em;
  font-weight: 600;
}
.p3 {
  margin-top: 10px;
}
.review-container {
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  max-width: 400px;
  min-width: 300px;
  padding: 20px 30px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
    rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
    rgba(0, 0, 0, 0.07) 0px 16px 16px;
  margin: 10px 20px;
  background-color: rgb(255, 255, 255);
}
.review-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.brands {
  width: 55vw !important;
  margin: 0 auto !important;
}
.reviews-main {
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  width: 90%;
  margin: 10px auto;
  padding: 0 10px;
}
.p1 {
  margin-left: 10px;

  font-size: 1.1em;
}
.stats_img {
  width: 50px;
  margin-top: 20px;
  height: 50px;
}
@media (max-width: 1000px) {
  .ServiceCard {
    margin-top: 20px;
    width: 90vw;
    height: max-content;
    padding: 20px 20px;
    min-height: min-content;
  }

  .Service-Card-title {
    margin: 0px;
  }
  .service-img {
    max-width: 100px;
    margin: 0 auto 10px auto;
  }
}
@media (max-width: 384px) {
  .Property-Tiles-Container {
    flex-direction: column;
  }
  .ServiceCard {
    min-width: 300px;
  }

  .stats {
    width: 65%;
  }
  .f-digit {
    font-size: 1.8em;
    margin-top: 10px;
  }
  .f-text {
    font-size: 1.1em;
  }
  .property-tile-content > h1 {
    font-size: 1.2em;
  }
  .reviews-main {
    justify-content: flex-start;
  }
}
